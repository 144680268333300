import React, { useContext, useEffect, useState, useRef } from "react";
// 3rd party imports
import { Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";

// material-ui
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import ConfigContext from "../store/context/ConfigContext";
import { Edit, Save } from "@mui/icons-material";
import MainCard from "../ui-component/cards/MainCard";
import SubCard from "ui-component/cards/SubCard";
import { useAuthHeaders } from "../auth/AuthHeaders";
import { availableRoles, roleMapping } from "../auth/availableRoles";
import CustomFormField from "../ui-component/form-fields/CustomFormField";
import { capitalize } from "../common/stringUtils";
// project imports

//-------------------------------------|| Account Setting -  VALIDATION || --------------------------------------//
const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
});

//-------------------------------------|| AccountSettings || --------------------------------------//

export function AccountSettings() {
  const authHeaders = useAuthHeaders();
  const config = useContext(ConfigContext);
  const [userdata, setUserData] = useState({});
  const [editMode, setEditMode] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const clientReference = sessionStorage.getItem("clientId");
  const shouldRefreshData = useRef(false);

  const getUserData = async () => {
    try {
      const response = await axios.post(
        `${config.API_URL}/login/v1/get_user`,
        {
          clientReference: clientReference,
          domainReference: `${config.LOGIN_DOMAIN_UUID}`,
          args: {},
        },
        { headers: authHeaders }
      );

      setUserData(response.data);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        sessionStorage.clear();
        window.location.href = "/token-expiry";
      } else {
        console.error("Error fetching user settings:", error);
      }
    }
  };

  useEffect(() => {
    if (shouldRefreshData.current) {
      getUserData().then(() => {
        shouldRefreshData.current = false;
      });
    }
  }, [shouldRefreshData.current]);

  useEffect(() => {
    getUserData();
  }, []);

  const initialFormValues = {
    name: userdata?.user?.name || "",
  };

  // Function to handle form submission
  const handleSubmit = async (values) => {
    try {
      await axios.post(
        `${config.API_URL}/login/v1/set_user`,
        {
          ...values,
        },
        { headers: authHeaders }
      );

      setEditMode(false);
      shouldRefreshData.current = true;
    } catch (error) {
      console.error("There was an error updating the name!", error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // check if the user is a bank user
  const shouldHideRolesTab = (realms) => {
    return realms.some(
      (realm) =>
        realm.id === clientReference &&
        realm.roles.some((role) => role.name === "bank::payment")
    );
  };

  const hideRolesTab = shouldHideRolesTab(userdata?.user?.realms || []);

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initialFormValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          submitForm,
        }) => (
          <MainCard title="Account Settings">
            <Box sx={{ width: "100%", mt: -2, mb: 1 }}>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="simple tabs"
              >
                <Tab label="User Profile" />
                {!hideRolesTab && <Tab label="Roles & Permissions" />}
              </Tabs>
            </Box>
            {tabValue === 0 && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SubCard>
                    <List>
                      {editMode ? (
                        <Form>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Grid item xs={8} md={3}>
                              <CustomFormField
                                name="name"
                                label="User name"
                                value={values.name}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                touched={touched}
                                errors={errors}
                              />
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                onClick={submitForm}
                                aria-label="save"
                                type={"button"}
                                color="primary"
                              >
                                <Save />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Form>
                      ) : (
                        <>
                          <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="center"
                          >
                            <Grid item xs={3} sx={{ ml: 2 }}>
                              <ListItemText
                                primary="User Name"
                                secondary={userdata?.user?.name}
                              />
                            </Grid>
                            <Grid xs={2}>
                              <IconButton
                                onClick={() => setEditMode(true)}
                                aria-label="edit"
                                color="primary"
                              >
                                <Edit />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </>
                      )}

                      {userdata?.user?.usernames?.map((username, index) => (
                        <ListItem key={index}>
                          <Grid container direction="column">
                            <Grid item>
                              <Typography>
                                {capitalize(username.type)}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <Typography color={"#697586"}>
                                {username.value}
                              </Typography>
                            </Grid>
                          </Grid>
                        </ListItem>
                      ))}
                    </List>
                  </SubCard>
                </Grid>
              </Grid>
            )}
            {tabValue === 1 && !hideRolesTab && (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SubCard>
                    <List>
                      {userdata?.user?.realms?.map((realm, index) => (
                        <React.Fragment key={index}>
                          <ListItem
                            alignItems="flex-start"
                            sx={{ paddingTop: 6 }}
                          >
                            <ListItemText
                              primary={"Client Account: " + realm.name}
                            />
                          </ListItem>
                          <Divider sx={{ mx: 2 }} />
                          <Grid container spacing={1}>
                            {availableRoles.map((role) => {
                              const isChecked = realm?.roles?.some(
                                (r) =>
                                  r.name === roleMapping[role.value] ||
                                  r.name === role.value
                              );
                              return (
                                isChecked && (
                                  <Grid
                                    item
                                    xs={6}
                                    sm={4}
                                    md={3}
                                    key={role.value}
                                    sx={{ ml: 2 }}
                                  >
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          defaultChecked
                                          style={{ color: "green" }}
                                          disabled
                                        />
                                      }
                                      label={role.label}
                                    />
                                  </Grid>
                                )
                              );
                            })}
                          </Grid>
                        </React.Fragment>
                      ))}
                    </List>
                  </SubCard>
                </Grid>
              </Grid>
            )}
          </MainCard>
        )}
      </Formik>
    </>
  );
}
