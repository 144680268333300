import React, { useContext, useEffect, useRef, useState } from "react";
// 3rd Party imports
import { useFormik } from "formik";
import PropTypes from "prop-types";
// materialUI imports
import { ExpandMore, Refresh } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
// project imports
import ConfigContext from "../../store/context/ConfigContext";
import { useAuthHeaders } from "../../auth/AuthHeaders";
import useFetchRate from "../../store/hooks/useFetchRate";
import { NumericFormat } from "react-number-format";
import CircularSpinner from "../CircularSpinner";
import { RolesContext } from "../../store/context/RolesContext";
import usdFlag from "../../assets/images/flags/usd_flag.png";
import { getCurrencies } from "../../store/hooks/useConfiguredCurrencies";
import { currencyOptions } from "../../common/CurrencyOptions";
import { getDecimalPlaces, shouldFixDecimalScale } from "../../common/currencyDecimalFormater";
import { selectCurrencyStyle, textFieldStyle } from "./CurrencyConverterStyles";

// ============================|| CURRENCY CONVERTER ||============================ //

const CurrencyConverter = ({
                             handleBack,
                             handleNext,
                             EntityId,
                             onApiResponse,
                             entityName,
                             whichInputTouched,
                             setWhichInputTouched,
                             ledgersData,
                             currency,
                             fxDealDetails,
                           }) => {
  const config = useContext(ConfigContext);
  const authHeaders = useAuthHeaders();
  const clientId = sessionStorage.getItem("clientId");
  const [rateCalculated, setRateCalculated] = useState(false);
  const [showInverseRate, setShowInverseRate] = useState(false);
  const { roles } = useContext(RolesContext);
  const userHasGetQuoteRole = roles.includes("quote");
  const [selectedLedger, setSelectedLedger] = useState({});
  const [loading, setLoading] = useState(true);
  const [sendCurrencyDisabled, setSendCurrencyDisabled] = useState(false);
  const [hasInputChanged, setHasInputChanged] = useState(false);

  const currencyOptionsFromGetCurrencies = getCurrencies();
  const rateDisclaimer = sessionStorage.getItem("rateDisclaimer");

  // Ledgers Logic - Needs to be revisited when we implement multi ledger accounts as right now
  // it basically thinks you have multi currency accounts only
  useEffect(() => {
    if (ledgersData.length > 0) {
      const usdLedger =
        ledgersData.find((ledger) => ledger.currency === "USD") ||
        ledgersData[0];
      setSelectedLedger(usdLedger);
      setLoading(false);
      formik.setFieldValue("sendCurrency", usdLedger.currency);
      formik.setFieldValue("ledgerId", usdLedger.ledgerId);
      if (ledgersData.length === 1) {
        setSendCurrencyDisabled(true);
      }
    }
  }, [ledgersData]);

  const availableCurrencies = ledgersData.map((ledger) => ({
    currency: ledger.currency,
    ledgerId: ledger.ledgerId,
  }));

  const sendCurrencyOptions = availableCurrencies.map((ledgerCurrency) => {
    const currencyOption = currencyOptions.find(
      (option) => option.value === ledgerCurrency.currency
    );
    return {
      ...ledgerCurrency,
      ...currencyOption,
    };
  });

  useEffect(() => {
    setWhichInputTouched("sellAmount");
  }, []);

  const {
    isLoading,
    fetchRate,
    submitCurrencyConversion,
    apiResponse,
    error,
  } = useFetchRate(config, authHeaders, clientId, EntityId, selectedLedger);

  const formik = useFormik({
    initialValues: {
      sellAmount:
        fxDealDetails?.request?.sellAmount || (!fxDealDetails?.request?.buyAmount ? "1000" : ""),
      buyAmount: fxDealDetails?.request?.buyAmount || "",
      sendCurrency: selectedLedger.currency || "USD",
      currency: userHasGetQuoteRole
        ? fxDealDetails?.request?.currency || currency || currencyOptionsFromGetCurrencies[0]?.value
        : "USD",
      ledgerId: selectedLedger.ledgerId,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const selectedSendCurrency = sendCurrencyOptions.find(
        (option) => option.value === values.sendCurrency
      );
      const fromLedgerId = selectedSendCurrency ? selectedSendCurrency.ledgerId : "";
      const enhancedApiResponse = (apiResponse) => {
        onApiResponse({
          ...apiResponse,
          sendCurrency: values.sendCurrency,
          buyAmount: apiResponse.buyAmount,
          sellAmount: apiResponse.sellAmount,
        });
      };
      submitCurrencyConversion(
        values,
        handleNext,
        enhancedApiResponse,
        fromLedgerId,
        whichInputTouched
      );
    },
  });

  const initialSellAmountRef = useRef(formik.values.sellAmount);
  const initialBuyAmountRef = useRef(formik.values.buyAmount);

  // Update field values when apiResponse changes
  useEffect(() => {
    if (apiResponse?.response.buyAmount && apiResponse?.response.sellAmount) {
      formik.setFieldValue("buyAmount", apiResponse.response.buyAmount);
      formik.setFieldValue("sellAmount", apiResponse.response.sellAmount);
      initialSellAmountRef.current = apiResponse.response.sellAmount;
      initialBuyAmountRef.current = apiResponse.response.buyAmount;
    }
  }, [apiResponse]);

// Logic around when to fetch a rate
  const handleBlur = (name) => {
    if (!hasInputChanged) return;

    const currentValue = parseFloat(formik.values[name]) || 0;
    const lastFetchedValue =
      name === "sellAmount"
        ? parseFloat(initialSellAmountRef.current) || 0
        : parseFloat(initialBuyAmountRef.current) || 0;


    if (currentValue !== lastFetchedValue) {
      const amountField = name;
      const amount = currentValue.toString();
      const selectedSendCurrency = sendCurrencyOptions.find(
        (option) => option.value === formik.values.sendCurrency
      );
      const fromLedgerId = selectedSendCurrency ? selectedSendCurrency.ledgerId : "";

      if (amount && formik.values.currency) {
        fetchRate(formik.values.currency, amountField, amount, fromLedgerId);
        setHasInputChanged(false);
      }
    }
  };


  const handleValueChange = (name, values) => {
    const previousValue = formik.values[name];
    const newValue = values.floatValue;

    if (previousValue !== newValue) {
      formik.setFieldValue(name, newValue);
      setHasInputChanged(true);
    }
  };

  const handleCurrencyChange = (e) => {
    const { name, value } = e.target;
    formik.setFieldValue(name, value);
    setRateCalculated(false);
  };

  // Calculate Initial Rate
  useEffect(() => {
    if (!rateCalculated) {
      const values = formik.values;
      const amountField = values.sellAmount ? "sellAmount" : "buyAmount";
      const amount = values[amountField];

      if (amount && values.currency) {
        const selectedSendCurrency = sendCurrencyOptions.find(
          (option) => option.value === values.sendCurrency
        );
        const fromLedgerId = selectedSendCurrency ? selectedSendCurrency.ledgerId : "";
        fetchRate(values.currency, amountField, amount, fromLedgerId);
        setRateCalculated(true);
      }
    }
  }, [formik.values.currency, rateCalculated, formik.values.sendCurrency]);

  const refetchRate = () => {
    const values = formik.values;
    const amountField = values.sellAmount ? "sellAmount" : "buyAmount";
    const amount = values[amountField];
    if (amount && values.currency) {
      const selectedSendCurrency = sendCurrencyOptions.find(
        (option) => option.value === values.sendCurrency
      );
      const fromLedgerId = selectedSendCurrency ? selectedSendCurrency.ledgerId : "";
      fetchRate(values.currency, amountField, amount, fromLedgerId);
    }
  };

  const handleKeyDown = (e, nextFieldId) => {
    if (e.key === "Enter") {
      e.preventDefault();
      const nextField = document.getElementById(nextFieldId);
      if (nextField) {
        nextField.focus();
      }
    }
  };

  if (loading) {return <CircularSpinner />;}

  return (
    <Container maxWidth="sm" sx={{ my: 5 }}>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ p: 1, bgcolor: "background.paper" }}>
          <Typography variant="body2" sx={{ color: "text.secondary", mb: 1 }}>
            You send exactly
          </Typography>
          <NumericFormat
            fullWidth
            variant="outlined"
            name="sellAmount"
            id="sellAmount"
            value={formik.values.sellAmount}
            onValueChange={(values) => handleValueChange("sellAmount", values)}
            onBlur={() => handleBlur("sellAmount")}
            onKeyDown={(e) => handleKeyDown(e, "buyAmount")}
            thousandSeparator
            decimalScale={getDecimalPlaces(formik.values.sendCurrency)}
            fixedDecimalScale={shouldFixDecimalScale(formik.values.sendCurrency)}
            customInput={TextField}
            onFocus={() => setWhichInputTouched("sellAmount")}
            sx={{
              ...textFieldStyle,
              "& .MuiSelect-icon": {
                display: "none",
              },
            }}
            disabled={isLoading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Select
                    name="sendCurrency"
                    value={formik.values.sendCurrency}
                    onChange={handleCurrencyChange}
                    displayEmpty
                    sx={selectCurrencyStyle}
                    disabled={sendCurrencyDisabled}
                  >
                    {sendCurrencyOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        <Box display="flex" alignItems="center">
                          <img
                            src={option.icon}
                            alt={option.label}
                            style={{ marginRight: "10px", height: "30px" }}
                          />
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </InputAdornment>
              ),
            }}
          />

          {isLoading && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                my: 2,
                height: "15px",
              }}
            >
              <CircularSpinner />
            </Box>
          )}

          {error && (
            <Box
              sx={{
                p: 1,
                bgcolor: "background.paper",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="h4" sx={{ flexGrow: 1, textAlign: "center" }}>
                Something went wrong, please try again
              </Typography>
              <IconButton onClick={refetchRate} size="small" aria-label="refresh" sx={{ ml: 2 }} color="secondary">
                <Refresh />
              </IconButton>
            </Box>
          )}
          {apiResponse?.response && !error && (
            <Box sx={{ p: 1, bgcolor: "background.paper", display: "flex", justifyContent: "flex-end" }}>
              <Grid container alignItems="center" spacing={1} justifyContent="flex-end">
                <Grid item>
                  <Typography variant="h3" sx={{ mr: 5 }}>
                    Rate: {apiResponse.response.rate}
                  </Typography>
                  {showInverseRate && (
                    <Typography variant="body1" sx={{ mr: 5, mt: 1 }}>
                      Inverse Rate: {apiResponse.response.inverse}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <IconButton onClick={() => setShowInverseRate(!showInverseRate)} size="small" aria-label="show inverse rate">
                    <ExpandMore />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          )}
          <Typography variant="body2" sx={{ color: "text.secondary", mb: 1 }}>
            {entityName} gets
          </Typography>
          <NumericFormat
            fullWidth
            variant="outlined"
            name="buyAmount"
            id="buyAmount"
            value={formik.values.buyAmount}
            onValueChange={(values) => handleValueChange("buyAmount", values)}
            onBlur={() => handleBlur("buyAmount")}
            onKeyDown={(e) => handleKeyDown(e, "submit")}
            onFocus={() => setWhichInputTouched("buyAmount")}
            thousandSeparator
            decimalScale={getDecimalPlaces(formik.values.currency)}
            fixedDecimalScale={shouldFixDecimalScale(formik.values.currency)}
            customInput={TextField}
            sx={textFieldStyle}
            disabled={isLoading}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Select
                    name="currency"
                    value={formik.values.currency}
                    onChange={handleCurrencyChange}
                    displayEmpty
                    sx={selectCurrencyStyle}
                    disabled={!userHasGetQuoteRole || isLoading}
                  >
                    {userHasGetQuoteRole ? (
                      currencyOptionsFromGetCurrencies.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          <Box display="flex" alignItems="center">
                            <img
                              src={option.icon}
                              alt={option.label}
                              style={{ marginRight: "10px", height: "30px" }}
                            />
                            {option.value}
                          </Box>
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem value="USD">
                        <Box display="flex" alignItems="center">
                          <img src={usdFlag} alt="USD" style={{ marginRight: "10px", height: "30px" }} />
                          USD
                        </Box>
                      </MenuItem>
                    )}
                  </Select>
                </InputAdornment>
              ),
            }}
          />
          {rateDisclaimer && (
            <Typography variant="body2" color="textSecondary" sx={{ fontStyle: "italic", fontWeight: "bold", fontSize: "0.875rem" }}>
              {rateDisclaimer}
            </Typography>
          )}
          <Button
            id="submit"
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2 }}
            disabled={!!error}
          >
            Next
          </Button>
          <Button
            onClick={handleBack}
            fullWidth
            variant="contained"
            sx={{ mt: 2 }}
            color={"secondary"}
          >
            Back
          </Button>
        </Box>
      </form>
    </Container>
  );
};

CurrencyConverter.propTypes = {
  EntityId: PropTypes.string.isRequired,
  handleBack: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  onApiResponse: PropTypes.func.isRequired,
  entityName: PropTypes.string.isRequired,
  setWhichInputTouched: PropTypes.func.isRequired,
};

export default CurrencyConverter;